.source-row>* {
    width: auto !important;
    align-items: center !important;
}

.source-header-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 34.13px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
}

.source-domain-section {
    background: #1B1D25;
    border-radius: 8px;
    padding: 0.25rem 0.5rem;
}

.source-domain-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
}

.disp-mob-none{
    display: block;
}

.disp-mob-block{
    display: none;
}

@media screen and (max-width: 768px) {
    .disp-mob-none{
        display: none;
    }

    .disp-mob-block{
        display: block;
    }

    .source-header-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: -0.03em;
        color: #DFDFDF;
    }

    .source-row {
        align-items: center;
        background: #1B1D25;
        padding: 1rem 0px;
    }

    .source-domain-section {
        background: #000;
        border-radius: 8px;
        padding: 0.25rem 0.5rem;
    }
}

.no-repos-found {
    min-height: 75vh;
    max-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}