.third-party{
.score-card{
    display: flex;
    flex-direction: row;
}
.score-text{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.96px;
    letter-spacing: 0.765463650226593px;
    text-align: left;
} 
.D{
    color: #FF4F4F;
}
.marks-text{
    font-family: Poppins;
font-size: 17px;
font-weight: 400;
line-height: 22.96px;
color: #FFF;
span{
    font-family: Poppins;
font-size: 13.37px;
font-weight: 400;
line-height: 22.96px;
}
}

.grow-text{
    color: #A6000D;
    border: 1px solid #A6000D;
    background: #FFE9EA;
    padding: 2px 4px;
    border-radius: 4px;
}
.progress-bar-wrapper{
    background: #FFE9EA;
    width: 100%;
    height: 9px;
    border-radius: 25px;
}
.third-party-progress-score-bar-sub{
    height: 9px;
    border-radius: 25px;
}
.vendor-text{
    color: #D3D3D3;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;    
}
.viewmore-wrapper{
    display: flex;
    align-items: center;
    justify-content: end;
}
.viewmore{
background: transparent;
color: #FFE9EA;
border: none;
outline: none;
}
@media screen and (max-width: 768px) {
    .width-135px{
        width: 70px;
        min-width: 70px;
    }
}
}