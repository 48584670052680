.payment-form{
    .domains-block {
    height: auto !important;
    overflow-y: scroll;
}
.font-32{
    font-size: 32px;

}
.font-28{
    font-size: 28px;
    
}
.btn {
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #16181D !important;
    border: 1px solid #FFFFFF !important;
    padding: 0.5rem 2rem !important;
}
.add-domain-btn {
    background: transparent !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #E6E6E6 !important;
        border: 1px solid #2B303B !important;
        padding: 0.5rem 1rem !important;
}

.add-on {
    .btn {
        background: transparent !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #E6E6E6 !important;
        border: 1px solid #2B303B !important;
        padding: 0.5rem 1rem !important;
    }
}
.mobile{
    color: #000 !important;
}
.danger-font{
    color: #FF0000;
}
.font-white{
    color: #FFFFFF;
}
.model-body{
    height: 300px !important;
    position: relative;
}
.center-align{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
}


.domains-section {
    width: 50% !important;
}

.plans-modal {
    .domains-section {
        width: 100% !important;
    }
}

.paypal-section {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 50vh; */
    width: 400px;
    > div{
        width: 400px !important;
    }
}

.paypal-body {
    overflow-y: scroll;
    display: grid;
    justify-content: center;
    margin-bottom: 16px;
}

.plan-suggestion  {
    color: #FFE976;
}

.form-select {
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27white%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    color: #FFFFFF !important;
    background-color: #333333 !important;
    border: none !important;
}

select {
    background-color: #333333 !important;
    border-radius: 4px;
    width: auto;
    height: 40px;
    border: none !important;
    color: #FFFFFF !important;
}

.countryOption{
    padding: 0 0 0 30px !important;
    height: 40px;
    width: 100px;
    background-repeat: no-repeat;
}