.wrapper{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: calc(100vh);
    width: 100%;
    background-color: #000;
    overflow-y: scroll;
    width: 100vw;
}

.pre-login-screen {
    background: black;
    width: 100vw;
    height: 100vh;
}

.header-section {
    background: #1B1D25;
    border-radius: 8px !important;
}

.aboutus-link {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    text-decoration: none !important;
    /* border-bottom: 1px solid linear-gradient(180deg, #35A6EB 0%, #BF3AED 100%); */
}
.h-110{
    height: 2px;
    width: 90%;
    margin-left: 5%;
    background: linear-gradient(180deg, #35A6EB 0%, #BF3AED 100%);
}
.signin-link {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    text-decoration: none !important;
}
.about-header-menu-userSection-textlable {
    padding: 4px 8px;
    margin: 0 8px;
    background: #1B1D25;
    border-radius: 4px;
    color: #ababab;
}

.about-header-menu-userSection-textlable-icon {
    background: #1B1D25;
    border-radius: 50%;
    font-size: 20px;
    color: #ebebeb;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    width: 2.25em;
    height: 2.25em;
}
  
.user-name {
    min-width: 225px;
}
.header-name{
    right: 80px;
    position: relative;

}

.user-icon {
    z-index: 100;
    top: -8px;
    right: -8px;
}

.username {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #ABABAB;
}

::-webkit-scrollbar {
    display: none !important;
  }

  .zsiq-float {
    background-color: #16181D !important;
    border-style: solid !important;
  }
  .about-wraper-header{
    padding-top: 3rem;
  }
.back-image{
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    background-image: url('./../../assets/images/back-image.png');
    background-repeat: no-repeat;
}
.hero-text{
font-family: Montserrat;
font-size: 64px;
font-weight: 600;
line-height: 78.02px;
letter-spacing: -0.03em;
color: #FFF;
}
.hero-sub-text{
font-family: Montserrat;
font-size: 24px;
font-weight: 400;
line-height: 32px;
letter-spacing: -0.03em;
color: #FFF;
}
.center-alligned{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.max-1100{
    max-width: 1100px;
}
.card-header{
    font-family: Montserrat;
font-size: 30px;
font-weight: 500;
line-height: 36.57px;
letter-spacing: -0.03em;
text-align: left;
color: #FFF;
}
.card-text{
    font-family: Montserrat;
font-size: 22px;
font-weight: 500;
line-height: 38px;
letter-spacing: -0.03em;
text-align: left;
color: #FFF;
padding-left: 16px;
}
.card{
    background: #16181D;
    color: #FFF;
    padding: 1rem;
}
.top-to-200{
    position: relative;
    top: -200px;
}
.center-image{
    display: flex;
    justify-content: center;
}

.gradient-text {
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: fit-content;
}

.strength {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
}

.pioneering {
    font-size: 48px;
    font-weight: 600;
    line-height: 58.51px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.strength-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.data-text {
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.support-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #E8E8E8;
}

.data-leaks-row {
    display: flex;
    align-items: center;
}

.customer-centric-card {
    border-radius: 8px;
    background: transparent;
    border: 2px solid #2B303B
}

.customer-centric-card-heading {
    font-size: 30px;
    font-weight: 500;
    line-height: 36.57px;
    letter-spacing: -0.03em;
    color: #FFFFFF;    
}

.customer-centric-card-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #E8E8E8;    
}

.leaks-section {
    background: #16181D;
    padding: 1.5rem;
    margin-top: 96px;

    .heading {
        font-size: 48px;
        font-weight: 600;
        line-height: 58.51px;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }
}

.leaks-counts-section {
    display: flex;
    justify-content: space-between;
    padding: 0 10rem;
    margin: 50px 0;
}

.leak-count {
    font-size: 64px;
    font-weight: 600;
    line-height: 78.02px;
    letter-spacing: -0.03em;
    color: #FFFFFF;    
}

.leak-count-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}
.back-mask{
    height: 80px;
    width: 350px;
    background: linear-gradient(360deg, #33A7EA -31.42%, #630294 28%);
    border-radius: 8px;
    margin: 0 auto;
}
.front-mask{
    top: 30px;
    width: 450px;
    background: #16181D;
    border-radius: 8px;
    padding: 50px;
    margin: -50px auto 0 auto;
    position: relative;
}
.front-mask::before{
    position: absolute;
    content: "";
    left: calc(50% - 42px);
    top: -34px;
    border-width: 34px;
    border-style: solid;
    background: #630294;
    border-color: transparent transparent #630294 #630294;
    transform: rotate(45deg);
    width: 50px;
    height: 50px;
}
.h-500-fix{
    position: relative;
    height: 700px;
    min-height: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.hr-border{
    border: 1px solid #494949 !important;
}
.team-user-icon{
    width: 108px;
height: 108px;
}
.left-50px{
    left: 50px;
}
.mb-md-2{
    margin-bottom: 0rem;
}
.p-r-32{
    padding-right: 32px !important;
}

@media screen and (max-width: 768px){
    .p-r-32{
        padding-right: 16px !important;
    }
    .about-wraper-header{
        padding-top: 0rem;
      }  
      .top-to-200{
        position: relative;
        top: -120px;
    }
    .mb-md-2{
        margin-bottom: 2rem;
    }
    .leaks-counts-section {
        display: block;
        text-align: center;
        padding: 0 0rem;
        margin: 0px 0;
    }
    .back-mask{
        height: 80px;
        width: 280px;
        background: linear-gradient(360deg, #33A7EA -31.42%, #630294 28%);
        border-radius: 8px;
        margin: 0 auto;
    }
    .front-mask{
        top: 30px;
        width: 380px;
        background: #16181D;
        border-radius: 8px;
        padding: 50px;
        margin: -50px auto 0 auto;
    }
    .h-500-fix{
        position: relative;
        height: 700px;
        min-height: 700px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .joinus-section{
        display: flex;
        flex-direction: column;
    }
    .order-1{
        order: 2 !important;
    }
    .order-2{
        order: 1 !important;
    }
    .team-user-icon{
        width: 54px;
        height: 54px;
    }
    .mobile-font{
        font-size: 14px;
    }
}