.text-left{
    text-align: left !important;
}
.continue-btn {
    background: transparent !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #E6E6E6 !important;
    border: 1px solid #2B303B !important;
    padding: 0.5rem 1rem !important;
}
.mobile{
    color: #000 !important;
}
.danger-font{
    color: #FF0000;
}

.invite-access {
    height: calc(100vh - 100px) !important;
}

.invite-block {
    border-radius: 8px;
    background: #16181D;
    border: 2px solid #2B303B;
}