.wrapper{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: calc(100vh);
    width: 100%;
    background-color: #000;
    overflow-y: scroll;
    width: 100vw;
}

.pre-login-screen {
    background: black;
    width: 100vw;
    height: 100vh;
}

.header-section {
    background: #1B1D25;
    border-radius: 8px !important;
}

.signin-link {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    text-decoration: none !important;
}
.aboutus-link {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    text-decoration: none !important;
}
.header-menu-userSection-textlable {
    padding: 4px 8px;
    margin: 0 8px;
    background: #1B1D25;
    border-radius: 4px;
    color: #ababab;
}

.header-menu-userSection-textlable-icon {
    background: #1B1D25;
    border-radius: 50%;
    font-size: 20px;
    color: #ebebeb;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    width: 2.25em;
    height: 2.25em;
}
  
.user-name {
    min-width: 225px;
}
.header-name{
    right: 80px;
    position: relative;

}

.user-icon {
    z-index: 100;
    top: -8px;
    right: -8px;
}

.username {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #ABABAB;
}
.landing-search{
.min-height-full{
min-height: 100% !important;
}
}

::-webkit-scrollbar {
    display: none !important;
  }

  .zsiq-float {
    background-color: #16181D !important;
    border-style: solid !important;
  }
  .wraper-header{
    padding-top: 1.5rem;
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    .landing-search{
        padding: 1em;
    }
    .wraper-header{
    padding-top: 0;
    margin-top: 0;
    }
    .mobile-font{
        font-size: 14px;
    }
}

.right-border {
    border-right: 1px solid #A6A6A6
}