.progress-bar{
    margin-top: 20px;
    background: linear-gradient(90deg, #EF1650 0%, #32D74B 100%);
    height: 8px;
    border-radius: 25px;
}
.font-white{
    color: #FFF;
}
.progressCircle{
    position: absolute;
    top: -21px;
    left: -10px;
    margin-left: -10px;
}
.hover-plane{
    background: #1B1D25;
    color: #FFF;
    position: absolute;
    top: -45px;
}
.background-13{
    background: #131313;
    padding: 16px;
    border-radius: 8px;
}
.w-96{
    width: 96%;
}
.w-2{
    width: 2%;
}
@media screen and (max-width: 768px) {
    .w-96{
        width: 100%;
    }
    .w-2{
        display: none !important;
    }
    .background-13{
        padding: 0;
        .nav-tabs{
            border: none !important;
        }
        .nav-link{
            color: #FFF;
        }
        .nav-tabs .nav-link.active{
            color: #FFF !important;
            border: none;
            border-bottom: 2px solid #FFF !important;
            background: none !important;
        }
    }
}

.text-amber {
    color: #FFE976
}
.loader{
    width: 100%;
    border-radius: 25px;
    background: #1B1D25;
    height: 12px;
}
.loader-mask{
    animation: loader-animation infinite 30s linear;
    height: 12px;
    border-radius: 25px;
    background: #FFFFFF;
}

@keyframes loader-animation {
    0%{
        width:0%;
    }
    10%{
        width:10%;
    }
    20%{
        width:20%;
    }
    30%{
        width:30%;
    }
    40%{
        width:40%;
    }
    50%{
        width:50%;
    }
    60%{
        width:60%;
    }
    70%{
        width:70%;
    }
    80%{
        width:80%;
    }
    90%{
        width:90%;
    }
    100%{
        width:100%;
    }
    
}