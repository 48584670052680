.notify-modal {
    .btn-close {
        display: none;
    }

    .btn {
        color: #FFFFFF !important;
        background: #000 !important;
        border: none !important;
    }

    .modal-content {
        top: 25vh !important;
        border: 2px solid #2B303B !important;
        min-height: 50vh !important;
        max-height: 70vh !important;
        right: 0vw !important;
        width: 35vw !important;

        overflow-y: scroll !important;
    }

    .modal-header {
        display: block !important;
        border-bottom: 1px solid #2B303B !important;
    }

    .domains-card {
        background: #1C1C1C;
        padding: 0.5rem !important;
    }

    .domains-block {
        height: 300px !important;
        overflow-y: scroll !important;
    }
}