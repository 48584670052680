.repo-files {
    .repo-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: -0.03em;
        color: #DFDFDF;
    }

    .repo-text-badge {
        font-size: 14px;
        font-weight: 500;
        line-height: 17.07px;
        letter-spacing: -0.03em;
        color: #16181D;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
    }

    .table {
        --bs-table-color-type: initial;
        --bs-table-bg-type: initial;
        --bs-table-color-state: initial;
        --bs-table-bg-state: initial;
        --bs-table-color: #FFFFFF;
        --bs-table-bg: transparent;
        --bs-table-border-color: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: var(--bs-emphasis-color);
        --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
        --bs-table-active-color: var(--bs-emphasis-color);
        --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
        --bs-table-hover-color: var(--bs-emphasis-color);
        --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
        width: 100%;
        margin-bottom: 1rem;
        vertical-align: top;
        border-color: var(--bs-table-border-color);
    }

    th {
        color: #A9A9A9 !important;
        font-size: 13px;
        font-weight: 500;
        line-height: 15.85px;
        letter-spacing: 0.5px;
        padding: 10px 0;
    }

    td {
        color: #D6D6D6 !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.31px;
        letter-spacing: 0.5px;
        padding: 4px 0;
    }

    .w-5 {
        width: 5%;
        max-width: 5vw !important;
    }

    .w-20 {
        width: 20%;
        max-width: 20vw !important;
    }

    .w-25 {
        width: 25%;
        max-width: 25vw !important;
    }

    .w-30 {
        width: 30%;
        max-width: 30vw !important;
    }

    .w-35 {
        width: 35%;
        max-width: 35vw !important;
    }

    td{
        overflow: hidden;
        word-wrap: break-word !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }

    .files-table {
        min-height: calc(100vh - 300px);
        max-height: calc(100vh - 300px);
        overflow-y: scroll !important;

        background-color: #000 !important;

        .tr-highlight {
            background: #1B1D25 !important;
        }
    }

    table {
        caption-side: bottom !important;
        border-collapse: collapse !important;
    }

    @media screen and (max-width: 768px){
        .hide-in-mobile{
            display: none !important;
        } 
    }

    @media screen and (min-width: 769px){
        .hide-in-desktop{
            display: none !important;
        } 
    }

    .green {
        background: #6CFF83;
    }

    .purple {
        background: #B68BFF;
    }

    .orange {
        background: #FBB767;
    }

    .yellow {
        background: #F8FB67;
    }
    .file-name {
        color: #FFFFFF !important;
        text-decoration: none;
    }

    .file-row-snippet {
        width: 70vw !important;
        background-color: #000 !important;
        overflow-x: scroll;

        td {
            border: none !important;
            max-width: 70vw !important;
        }

        pre {
            white-space: pre-wrap !important;
            display: contents !important;
        }
    }

}

.no-hits-found {
    min-height: 60vh;
    max-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}