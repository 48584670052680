.terms-of-service{
    h1, h2 {
    /* color: #446688; */
    margin-top: 0;
}
h2 {
    margin-bottom: 10px;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    margin-bottom: 10px;
}
a {
    /* color: #446688; */
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
max-width: fit-content;
}
a:hover {
    text-decoration: underline;
}
.font-52{
    font-size: 52px;
}
}
.back-black{
    background: #000000;
    color: #FFFFFF;
    padding: 4rem 2rem;
}