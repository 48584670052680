.header-border {
    /* border-bottom: 1px solid #4A4A4A; */
}
.app-header {
    padding: 1rem;
    background-color: black;
    /* max-height: 64px; */
    display: flex;
    /* flex-direction: column; */
    /* font-size: calc(10px + 2vmin); */
    color: white;
  }

.header-menu-userSection-textlable {
    padding: 4px 42px 4px 8px !important;
    margin: 0 8px !important;
    background: #16181D !important;
    border-radius: 4px !important;
    color: #ABABAB !important;
}

.header-menu-userSection-textlable-icon {
    background: #2c2c2c;
    border-radius: 50%;
    font-size: 20px;
    color: #ebebeb;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    width: 2.25em;
    height: 2.25em;
}
  
.user-name {
    min-width: auto !important;
}

.user-icon {
    z-index: 100;
    top: -8px;
    right: -8px;
}

.username {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #ABABAB;
}
.position-unset{
    position: unset;
}

.user-name {
    .dropdown-toggle::after {
        display: none !important;
    }

    .dropdown-item {
        text-decoration: none !important;
    }
}

.letter-spacing-normal {
    letter-spacing: normal !important;
}

.font-18 {
    font-size: 18px !important;
}

.api-key-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
}

.api-key-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #FFE976;
}

.api-doc-section {
    background: #1B1D25;
    border-radius: 8px;
    padding: 0.75rem;
}

.api-docs-link {
    background: #1B1D25;
    border-radius: 8px;
    padding: 0.5rem;
    color: #DFDFDF;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.api-docs-link:hover {
    background: #DFDFDF;
    border-radius: 8px;
    padding: 0.5rem;
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .pre-login-content-block {
    width: 100%;
    }
    .disp-mob-none{
        display: none;
    }
    .disp-lg-none{
        display: none;
    }
    .disp-mob-block{
    display: block;
    }
}