.username-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.choose-text {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.choose-sub-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.plan-card {
    background: #1C1C1C;
    min-height: 400px;
    max-height: 400px;
    padding: 1.5rem;
}

.plan-name {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.plan-cost {
    font-size: 40px;
    font-weight: 700;
    line-height: 46.88px;
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-content {
    height: 300px;
}
.color-white{
    color: white;
}