.modal-content {
    background: #1c1c1c !important;
    color: #FFFFFF !important;
}

.font-12 {
    font-size: 12px;;
}

.sub-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: -0.03em;
    color: #515560;
}

.domains-modal {
    .btn-close {
        display: none;
    }

    .btn {
        color: #FFFFFF !important;
        background: #000 !important;
        border: none !important;
    }

    .modal-content {
        top: 15vh !important;
        border: 2px solid #2B303B !important;
    }

    .modal-header {
        display: block !important;
        border-bottom: 1px solid #2B303B !important;
    }

    .domains-card {
        background: #1C1C1C;
        padding: 0.5rem !important;
    }

    .domains-block {
        height: 300px !important;
        overflow-y: scroll !important;
    }
}

input {
    background: #333333 !important;
}

::-ms-input-placeholder { /* Edge 12 -18 */
    color: #E6E6E6 !important;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #E6E6E6 !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: #E6E6E6 !important;
  }

  ::placeholder {
    color: #E6E6E6 !important;
}

.continue-btn {
    .btn {
        background: #FFFFFF !important;
        color: #000 !important;
        border-radius: 8px !important;
        padding: 0.5rem 1rem !important;
    }
}
.grow-text{
    color: #A6000D;
    border: 1px solid #A6000D;
    background: #FFE9EA;
    padding: 2px 4px;
    border-radius: 4px;
}
.progress-bar-wrapper{
    background: #FFE9EA;
    width: 100%;
    height: 9px;
    border-radius: 25px;
}
.progress-score-bar-sub{
    width: 50%;
    height: 9px;
    border-radius: 25px;
    background: #FF4F4F;
}
.logo{
    width: 48px;
    height: 48px;
}
.filedroper{
    width: 100px;
    height: 100px;
    color: #c3c3c3;
    border: 1px dashed #c3c3c3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.files-table {
    tr {
        border: 0 !important;
    }
    tr{
        border-bottom: 1px solid #2E323E !important;
    }

    .tr-highlight {
        background: #1B1D25 !important;
    }
  }

  .expanded-details {
    background-color: #000 !important;
    min-height: 50px;
}

@media screen and (max-width: 768px){
    .hide-in-mobile{
    display: none !important;
   } 
}
.error-text{
    color:#FF0000;
    font-size: 11px;
    margin-left: 1rem;
}

.w-5 {
    width: 5%;
    max-width: 5vw !important;
}

.w-10 {
    width: 10%;
    max-width: 10vw !important;
}

.w-15 {
    width: 15%;
    max-width: 15vw !important;
}

.w-50 {
    width: 50%;
    max-width: 50vw !important;
}

.filter-checkbox {
    background: #131313 !important;
    border-radius: 4px !important;
    padding: 0.4rem 0.4rem !important;
    max-height: 33px !important;

    label {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 14.63px !important;
        letter-spacing: 0.7228261232376099px !important;
        color: #C1C1C1 !important;
    }

    .form-check-input:checked {
        background-color: #00A656 !important;
        border-color: #00A656 !important;
    }
  }
.create-btn{
    color: #E6E6E6 !important;
    padding: 0.5em 1em !important;
    border: none !important;
    border-radius: 4px !important;
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
}
@media screen and (max-width: 768px){
    .hide-in-mobile{
        display: none !important;
    } 

    .nav-tabs{
        border: none !important;
    }
    .nav-link{
        color: #FFF !important;
    }
    .nav-tabs .nav-link.active{
        color: #FFF !important;
        border: none;
        border-bottom: 2px solid #FFF !important;
        background: none !important;
    }

    .filter-checkbox {
        background: #131313 !important;
        border-radius: 4px !important;
        padding: 0.4rem 0.4rem !important;
        max-height: 50px !important;
    
        label {
            font-size: 12px !important;
            font-weight: 600 !important;
            line-height: 14.63px !important;
            letter-spacing: 0.7228261232376099px !important;
            color: #C1C1C1 !important;
        }
    
        .form-check-input:checked {
            background-color: #00A656 !important;
            border-color: #00A656 !important;
        }
      }
}