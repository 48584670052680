.search{
    /* display: grid;
    justify-content: center;
    align-items: center; */
    height: calc(100vh - 64px);
}
.search-field{
    background: #FFFFFF !important;
    border-bottom: 1px solid #848484 !important;
    border: none;
    border-radius: 0;
    color: #000 !important;
}
.search-field::placeholder{
    color: #000 !important;
}
.search-field:focus{
    background: #333;
    color: #fff;
    border: none;
    outline: none;
}
.info-text{
    color: #848484;
    font-size: 1.5rem;
    text-align: center;
}

.form-select-lg {
    border-radius: 4px !important;
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
}

.line-text-1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
}

.line-text-2 {
    font-size: 56px;
    font-weight: 600;
    line-height: 78.02px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.line-text-3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.discover-btn {
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #131313;
    cursor: pointer;
}
.discover-btn:hover{
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    .hide-on-hover{
        display: none;
    }
    .d-on-hover{
        display: inline-block !important;
    }
}

.counts-card {
    background: #16181D;
}

.cards-count {
    font-size: 56px;
    font-weight: 600;
    line-height: 78.02px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.total-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #FFFFFF
}

.know-yours {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.search-block {
    border-radius: 8px 0px 0px 0px;
    background: #16181D;
    border: 2px solid #2B303B
}

.w-10 {
    width: 10%;
}

.w-50 {
    width: 50%;
}

.search-count {
    font-size: 36px;
    font-weight: 600;
    line-height: 43.88px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.search-count-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.last-days-data {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    /* color: #d687fed4; */
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* max-width: -moz-fit-content !important; */
    /* max-width: fit-content; */
}

.more-data-found {
    font-size: 14px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    /* color: #d687fed4; */
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* max-width: -moz-fit-content !important; */
    /* max-width: fit-content; */
}

.subscribe-signup-btn {
    padding: 1rem;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    border: 1px solid #2B303B;
    width: 100%;
    display: block;
    text-align: center;
}

.found-data-sets {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.31px;
    letter-spacing: 0.5px;
    color: #D6D6D6;
}

.data-bottom-section {
    border-top: 1px solid #2B303B;
    /* color: #d687fed4; */
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: -moz-fit-content !important;
    max-width: fit-content;
}

.table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: #FFFFFF;
    --bs-table-bg: transparent;
    --bs-table-border-color: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-emphasis-color);
    --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
    --bs-table-active-color: var(--bs-emphasis-color);
    --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
    --bs-table-hover-color: var(--bs-emphasis-color);
    --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: var(--bs-table-border-color);
}

th {
    color: #A9A9A9 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    letter-spacing: 0.5px;
    padding: 10px 0;
}

td {
    color: #D6D6D6 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.31px;
    letter-spacing: 0.5px;
    padding: 4px 0;
}

.week {
    color: #FF4F4F;
}

.w-3 {
    width: 3%;
}

.w-10 {
    width: 10%;
}

.w-12 {
    width: 12%;
}

.w-13 {
    width: 13%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.cursor-pointer {
    cursor: pointer;
}

.steeler-details {
    background-color: #2E2E2E !important;
    min-height: 50px;
}

.stealer-header {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    color: #DFDFDF;
}

.stealer-value {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    color: #878787;
    margin-top: 0.5rem;
}
.height-75{
    max-height: calc(100vh - 250px) !important;
    overflow: hidden !important;
}

.gradient-text {
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
max-width: fit-content;
}
    @media screen and (max-width: 768px){
        .disp-mb-none{
    display: none;
    }
}