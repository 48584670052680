.sub-success{
.success-card {
    background: #16181D;
    border: 1px solid #2B303B;
}

.success-msg {
    font-size: 48px;
    font-weight: 900;
    line-height: 60.24px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.active-msg {
    font-size: 18px;
    font-weight: 400;
    line-height: 29.3px;
    color: #767676;
}

.goto-btn {
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #16181D !important;
    border: 1px solid #FFFFFF !important;
    padding: 0.5rem 2rem !important;
}

.btn {
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #16181D !important;
    border: 1px solid #FFFFFF !important;
    padding: 0.5rem 2rem !important;
}
}