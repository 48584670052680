.subscription-plans {
    .plans-heading {
        font-size: 20px;
        font-weight: 600;
        line-height: 24.38px;
        letter-spacing: -0.03em;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        max-width: fit-content;
    }

    .w-33 {
        width: 33%;
    }

    .plans-sub-heading {
        font-size: 64px;
        font-weight: 600;
        line-height: 78.02px;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }

    .premium {
        position: relative;
        cursor: pointer;
        background: #16181D;
        padding: 2px;
        border-radius: 8px;
        border: 2px solid #2B303B;
        height: 52vh;

        .sub-plan-card {
            position: absolute;
            background: #16181D;
            border-radius: 8px;
            height: calc(52vh - 8px);
            padding: 0;
            margin: 0;
            top: 2px;
            width: 99%;
        }

        .plan-label-bg {
            background: #651591;
            border-radius: 0px 4px 4px 0px;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: #FFFFFF;
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;
        }
        .btn {
            background: transparent;
            border: 1px solid #2B303B;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.92px;
            color: #FFFFFF;
        }
    }

    .premium:hover {
        position: relative;
        cursor: pointer;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
        padding: 2px;
        border-radius: 8px;
        border: none;
        height: 52vh;

        .sub-plan-card {
            position: absolute;
            background: #16181D;
            border-radius: 8px;
            height: calc(52vh - 4px);
            width: 99%;
        }
        .plan-label-bg {
            background: #B244EE;
            border-radius: 0px 4px 4px 0px;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: #FFFFFF;
            display: grid;
            justify-content: center;
            align-items: center;
        }
        .btn {
            background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
            border-radius: 8px;
            border: none;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.92px;
            color: #FFFFFF;
        }
        
    }

    .business {
        position: relative;
        cursor: pointer;
        background: #16181D;
        padding: 2px;
        border-radius: 8px;
        border-top: 2px solid #2B303B;
        border-right: 2px solid #2B303B;
        border-bottom: 2px solid #2B303B;
        height: calc(52vh - 32px);

        .sub-plan-card {
            position: absolute;
            background: #16181D;
            border-radius: 8px;
            height: calc(52vh - 40px);
            padding: 0;
            margin: 0;
            top: 2px;
            width: 99%;
        }

        .plan-label-bg {
            background: #651591;
            border-radius: 0px 4px 4px 0px;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: #FFFFFF;
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;
        }
        .btn {
            background: transparent;
            border: 1px solid #2B303B;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.92px;
            color: #FFFFFF;
        }
    }

    .business:hover {
        position: relative;
        cursor: pointer;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
        padding: 2px;
        border-radius: 8px;
        border: none;
        height: calc(52vh - 32px);

        .sub-plan-card {
            position: absolute;
            background: #16181D;
            border-radius: 8px;
            height: calc(52vh - 36px);
            width: 99%;
        }
        .plan-label-bg {
            background: #B244EE;
            border-radius: 0px 4px 4px 0px;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: #FFFFFF;
            display: grid;
            justify-content: center;
            align-items: center;
        }
        .btn {
            background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
            border-radius: 8px;
            border: none;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.92px;
            color: #FFFFFF;
        }
    }

    .onetime {
        position: relative;
        cursor: pointer;
        background: #16181D;
        padding: 2px;
        border-radius: 8px;
        border-top: 2px solid #2B303B;
        border-right: 2px solid #2B303B;
        border-bottom: 2px solid #2B303B;
        height: calc(52vh - 64px);

        .sub-plan-card {
            position: absolute;
            background: #16181D;
            border-radius: 8px;
            height: calc(52vh - 72px);
            padding: 0;
            margin: 0;
            top: 2px;
            width: 99%;
        }

        .plan-label-bg {
            background: #651591;
            border-radius: 0px 4px 4px 0px;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: #FFFFFF;
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;
        }
        .btn {
            background: transparent;
            border: 1px solid #2B303B;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.92px;
            color: #FFFFFF;
        }
    }

    .onetime:hover {
        position: relative;
        cursor: pointer;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
        padding: 2px;
        border-radius: 8px;
        border: none;
        height: calc(52vh - 64px);

        .sub-plan-card {
            position: absolute;
            background: #16181D;
            border-radius: 8px;
            height: calc(52vh - 68px);
            width: 99%;
        }
        .plan-label-bg {
            background: #B244EE;
            border-radius: 0px 4px 4px 0px;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: #FFFFFF;
            display: grid;
            justify-content: center;
            align-items: center;
        }
        .btn {
            background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
            border-radius: 8px;
            border: none;
            font-size: 18px;
            font-weight: 700;
            line-height: 25.92px;
            color: #FFFFFF;
        }
    }

    .pricing-row {
        display: flex;

        .dollar {
            font-size: 24px;
            font-weight: 300;
            line-height: 28.13px;
            background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            max-width: fit-content;
        }

        .price {
            font-size: 40px;
            font-weight: 700;
            line-height: 46.88px;
            background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            max-width: fit-content;
        }
    }

    .card-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #FFFFFF;
    }

    .pricing-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 12px;
        color: #FFFFFF;
    }
    .pricing-text-sub {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: #FFFFFF;
    }

    .plan-title-row {
        display: flex;
    }
}