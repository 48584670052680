.page-not-found {
    width: 100vw !important;
    height: calc(100vh - 100px) !important;
    display: grid !important;
    justify-content: center !important;
    align-items: center !important;
}

.p1 {
    color: #DFDFDF;
    font-size: 64px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.03em;
}

.p2 {
    color: #DFDFDF;
    font-size: 32px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.03em;
}

.p3 {
    color: #DFDFDF;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: -0.03em;
}

.home-btn {
    background-color: #FFFFFF !important;
    border: none !important;
    color: #000000 !important;
    border-radius: 4px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    letter-spacing: -0.03em !important;
}