.domains{
.domains-card {
    background: #1C1C1C;
    padding: 1.5rem;
}

.domains-cost-card {
    background: #1C1C1C;
    padding: 1.5rem;
    height: max-content;
}

.domain-company-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.add-domains-text {
    font-size: 22px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}
    .plan-cost {
        font-size: 28px;
        font-weight: 700;
        line-height: 46.88px;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

.domains-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.goto-btn {
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #16181D !important;
    border: 1px solid #FFFFFF !important;
    padding: 0.5rem 2rem !important;
}

.btn {
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #16181D !important;
    border: 1px solid #FFFFFF !important;
    padding: 0.5rem 2rem !important;
}

.form-control {
    border: none;
    color: #FFFFFF !important;
}

.form-control-lg {
    font-size: 1rem !important;
    color: #FFFFFF !important;
}

input {
    background: #000000 !important;

}

::-ms-input-placeholder { /* Edge 12 -18 */
    color: #E6E6E6 !important;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #E6E6E6 !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: #E6E6E6 !important;
  }

  ::placeholder {
    color: #E6E6E6 !important;
}

.error-msg {
    color: red;
    font-size: 14px !important;
}

.domains-block {
    height: 350px;
    overflow-y: scroll;
}

.add-domain-btn {
    background: transparent !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #E6E6E6 !important;
        border: 1px solid #2B303B !important;
        padding: 0.5rem 1rem !important;
}

.add-on {
    .btn {
        background: transparent !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #E6E6E6 !important;
        border: 1px solid #2B303B !important;
        padding: 0.5rem 1rem !important;
    }
}

::-webkit-scrollbar {
    display: none !important;
  }
}
  
  .domains {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }