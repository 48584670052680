.payment-method {
    .modal-content {
        background: #1c1c1c !important;
        color: #FFFFFF !important;
        top: 25vh !important;
        min-height: 35vh !important;
        max-height: 35vh !important;
        right: 0vw !important;
        width: 25vw !important;
    }
}