.reset-success-msg {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.pre-login-content-screen {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -100px;

    input {
        height: 48px !important;
    }
}