.payment-admin{
    .text-left{
    text-align: left !important;
}
.continue-btn {
    background: transparent !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #E6E6E6 !important;
    border: 1px solid #2B303B !important;
    padding: 0.5rem 1rem !important;
}
.mobile{
    color: #000 !important;
}
.danger-font{
    color: #FF0000;
}

.invite-access {
    height: calc(100vh - 100px) !important;
}

.invite-block {
    border-radius: 8px;
    background: #16181D;
    border: 2px solid #2B303B;
}

    background-color: black;
  height: calc(100vh - 67px);

    .search-field{
        background: #000 !important;
        border-bottom: 1px solid #000 !important;
        border: none;
        border-radius: 0;
        color: #fff !important;
    }

    .search-field::placeholder{
        color: #FFFFFF !important;
    }
    .search-field:focus{
        background: #333;
        color: #fff;
        border: none;
        outline: none;
    }

.top-section-border {
    /* border-bottom: 1px solid #4A4A4A; */
    background: #1B1D25 !important;
    border-radius: 8px;
}

.comprimised-users {
    color: #DFDFDF;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    letter-spacing: 0.5px;
}

.comprimised-users-count {
    color: #DADADA;
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
}

.table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: #FFFFFF;
    --bs-table-bg: transparent;
    --bs-table-border-color: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-emphasis-color);
    --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
    --bs-table-active-color: var(--bs-emphasis-color);
    --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
    --bs-table-hover-color: var(--bs-emphasis-color);
    --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: var(--bs-table-border-color);
}

th {
    color: #A9A9A9 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    letter-spacing: 0.5px;
    padding: 10px 0;
}

td {
    color: #D6D6D6 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.31px;
    letter-spacing: 0.5px;
    padding: 4px 0;
}

.week {
    color: #FF4F4F;
}

.w-3 {
    width: 3%;
    max-width: 3vw !important;
}

.w-10 {
    width: 10%;
    max-width: 10vw !important;
}

.w-12 {
    width: 12%;
    max-width: 12vw !important;
}

.w-13 {
    width: 13%;
    max-width: 13vw !important;
}

.w-15 {
    width: 15%;
    max-width: 15vw !important;
}

.w-20 {
    width: 20%;
    max-width: 20vw !important;
}

.w-25 {
    width: 25%;
    max-width: 25vw !important;
}

.w-70 {
    width: 70%;
    max-width: 70vw !important;
}
.w-35{
    width: 35%;
    max-width: 35vw !important;
}
td{
    overflow: hidden;
    word-wrap: break-word !important;
}

.cursor-pointer {
    cursor: pointer;
}

.steeler-details {
    background-color: #000 !important;
    min-height: 50px;
}

.stealer-header {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    color: #DFDFDF;
}

.stealer-value {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    color: #878787;
    margin-top: 0.5rem;
}
.height-75{
    min-height: 50vh;
    max-height: calc(100vh - 250px);
    overflow-y: scroll !important;
}

::-webkit-scrollbar {
    display: none !important;
  }

  .leaked-table {
    background-color: #000 !important;
    tr {
        border: 0 !important;
    }
    tr{
        border-bottom: 1px solid #2E323E !important;
    }

    .tr-highlight {
        background: #1B1D25 !important;
    }
  }

  .filter-checkbox {
    background: #131313 !important;
    border-radius: 4px !important;
    padding: 0.5rem 0.75rem !important;

    label {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 14.63px !important;
        letter-spacing: 0.7228261232376099px !important;
        color: #C1C1C1 !important;
    }

    .form-check-input:checked {
        background-color: #00A656 !important;
        border-color: #00A656 !important;
    }
  }
.url-link {
    text-decoration: none !important;
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

  table {
    caption-side: bottom !important;
    border-collapse: collapse !important;
  }
   @media screen and (max-width: 768px){
    .hide-in-mobile{
    display: none !important;
   } 
}
    @media screen and (min-width: 769px){
    .hide-in-desktop{
     display: none !important;
    } 
  }

  .modal-content {
    background: #1c1c1c !important;
    color: #FFFFFF !important;
}

.font-12 {
    font-size: 12px;;
}

.sub-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: -0.03em;
    color: #515560;
}

.plans-modal {
    .btn-close {
        display: none;
    }

    .btn {
        color: #FFFFFF !important;
        background: #000 !important;
        border: none !important;
    }

    .modal-content {
        top: 4vh !important;
        border: 2px solid #2B303B !important;
        max-height: 85vh !important;
        overflow-y: scroll !important;
    }

    .modal-header {
        display: block !important;
        border-bottom: 1px solid #2B303B !important;
    }

    .domains-card {
        background: #1C1C1C;
        padding: 0.5rem !important;
    }

    .domains-block {
        height: 300px !important;
        overflow-y: scroll !important;
    }
}

.continue-btn {
    .btn {
        background: #FFFFFF !important;
        color: #000 !important;
        border-radius: 8px !important;
        padding: 0.5rem 1rem !important;
    }
}
}