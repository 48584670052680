.verticle-scroller{
    width: 100%;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 10px;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}
.title{
    text-transform: capitalize;
}
.scroller{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    overflow-y: scroll;
    min-height: 90%;
    max-height: 90%;
}
.count-label{
    background: #FFF;
    padding: 2px 4px;
    margin-left: 8px;
    border-radius: 2px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: black;

}
.filter-options{
    height: 30px !important;
    padding-top: 4px !important;
    padding-left: 4px !important;
    background: transparent;
    color: #FFF;
    border: none;
    border-bottom: 1px solid #FFF;
    border-radius: 0 !important;
    font-size: 14px !important;
}
.filter-options.form-select{
    background-image: url('./../../assets/images/dropdown.png') !important;
    background-repeat: no-repeat;
    background-position: 90%;
    background-size: 16px;
}
.failed{
    color: #FF0000;
    background: #1B1D25;
    padding: 4px 8px !important;
}
.passed{
    color: #32D74B;
    background: #1B1D25;
    padding: 4px 8px !important;
}
.font-small{
    font-size: 9px !important;
    color: #969696;
}

.modal-content {
    background: #1c1c1c !important;
    color: #FFFFFF !important;
    top: 5vh !important;
    min-height: 80vh !important;
    max-height: 80vh !important;
    right: 15vw !important;
    width: 65vw !important;
}

.modal-header .btn-close {
    display: none !important;
}

.modal-title {
    color: #f0dc70;
}

.modal-body-text {
    min-height: 65vh !important;
    max-height: 65vh !important;
    overflow-y: scroll !important;

    .suggested-text {
        p,li{
            font-size: 14px !important;
        }
    
        a {
            font-size: 14px !important;
            color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1)) !important;
        }
    
        pre {
            font-size: 12px !important;
            background: #000 !important;
            padding: 0.75rem;
            border-radius: 8px;
            white-space: pre-wrap !important;
        }
    
        h3,h4 {
            margin-bottom: 1rem;
        }
    }
}

.brand-issue-header {
    .btn-primary {
        background-color: #6c757d !important;
        color: #FFFFFF !important;
        border: none !important;
    }
}

.font-14 {
    font-size: 14px !important;
}

@media screen and (max-width: 768px) {
    .verticle-scroller{
        border: none;
    }
    .title{
        display: none;
    }

    .modal-content {
        background: #1c1c1c !important;
        color: #FFFFFF !important;
        top: 5vh !important;
        min-height: 80vh !important;
        max-height: 80vh !important;
        right: unset !important;
        width: unset !important;
    }
}