.full-width{
    width: 100vw;
    height: 100vh;
    position: relative;
    color: #FFF;
    background: #000;
}
.center-alligned{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.home-button{
    background: #FFF;
    border-radius: 4px;
    outline: none;
    color: #000;
}

.unsubscribe-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}