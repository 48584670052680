.modal-content {
    background: #1c1c1c !important;
    color: #FFFFFF !important;
    top: 20vh;
    min-height: 52vh;
    max-height: 52vh;
}

.files-section {
    min-height: 290px !important;
    max-height: 290px !important;
    overflow-y: scroll !important;
}

.generated-date {
    font-size: 14px !important;
}

.modal-header .btn-close {
    display: none !important;
}

.export-header {
    .btn-secondary {
        --bs-btn-color: #fff;
        --bs-btn-bg: #6c757d;
        --bs-btn-border-color: #6c757d;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #6c757d !important;
        --bs-btn-hover-border-color: #6c757d !important;
        --bs-btn-focus-shadow-rgb: 130, 138, 145;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #565e64;
        --bs-btn-active-border-color: #51585e;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #6c757d;
        --bs-btn-disabled-border-color: #6c757d;
    }
}

.exporting {
    width: 100px;
    background: #494949;
    padding: 0.5rem;
    border-radius: 4px;
}

.error-msg {
    color: red;
}

.file-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #141414;
    padding: 0 0.6rem;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
}

.inprogress {
    font-size: 14px;
    color: #f0dc70;
}