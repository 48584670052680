.sub-card{
    padding: 32px;
    background: #16181D;
    border-radius: 8px;
}
.sub-card-header{
    color: #FFF;
    /* font-family: Montserrat; */
font-size: 64px;
font-weight: 600;
line-height: 78.02px;
letter-spacing: -0.03em;

}
.sub-card-body{
    color: #FFF;
    /* font-family: Montserrat; */
font-size: 24px;
font-weight: 400;
line-height: 29.26px;
letter-spacing: -0.03em;
text-align: left;

}
.sub-card-footer{
font-size: 20px;
font-weight: 600;
background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
max-width: fit-content;
}

.gradient-text {
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
max-width: fit-content;
}
.concerns-section{
    text-align: center;
}
.heder-line{
    height: 1px;
    background: #2B303B;
    width: 80%;
    margin: 80px auto 10px auto;
}
.line{
    background: linear-gradient(90deg, #C038ED 0%, #32A7EB 100%);
    animation: loader-animation infinite 15s linear;
}
@keyframes loader-animation {
    0%{
        width:0%;
    }
    10%{
        width:10%;
    }
    20%{
        width:20%;
    }
    30%{
        width:30%;
    }
    40%{
        width:40%;
    }
    50%{
        width:50%;
    }
    60%{
        width:60%;
    }
    70%{
        width:70%;
    }
    80%{
        width:80%;
    }
    90%{
        width:90%;
    }
    100%{
        width:100%;
    }
    
}
.transparent{
    background: transparent;
}
.carousal-line{
    width: 100%;
    height: 1px;
}
.landscape-text{
    margin-top: 120px;
    margin-bottom: 24px;
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.concerns-heading{
font-size: 64px;
font-weight: 600;
line-height: 78.02px;
letter-spacing: -0.03em;
text-align: center;
color: #FFF;
margin: auto;
width: 60vw;
margin-bottom: 120px;
}
.concern-card-wrapper{
    background: none;
    padding: 2px;
    height: 35vh;
    min-height: 240px;
    position: relative;
}
.concern-card-wrapper-highlight{
    background: linear-gradient(229.7deg, rgba(0, 0, 0, 0) 1.39%, #206174 48.13%, #A62EC3 100%);
    padding: 2px;
    height: 35vh;
}
.concern-card{
padding: 1.5rem;
background: #16181D;
height: 100%;
}
.concern-card-text{
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: -0.03em;
text-align: left;
color: #FFF;
}
.concern-card-header-text{
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #FFF;
}
.margin-bottom-50{
    margin-bottom: 50px;
}
.margin-top-50{
    margin-top: 50px;
}

.find-btn{
    background: transparent !important;
    border: 1px solid #2B303B !important;
    border-radius: 8px;

    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.find-btn:hover {
    background: #FFFFFF !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 8px;

    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #131313;
  }
.trusted-section{
    padding: 6rem;
    height: 100vh;
}

.overflow-scroll{
    overflow-y: scroll;
    height: 70vh;
    max-height: 70vh;
}

.trusted-text{
font-size: 64px;
font-weight: 600;
line-height: 78.02px;
letter-spacing: -0.03em;
text-align: left;
color: #FFF;
}

.purple-text{
    /* background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: #AC4AED;

}
.trusted-card{
    padding: 2rem;
    background: #16181D;
    border-radius: 8px;
    width: 100%;
    margin: 1rem 0.75rem;
    border: 2px solid #2B303B !important;
}

.trusted-card-heading{
    font-size: 30px;
    font-weight: 500;
    line-height: 36.57px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #FFF;
}
.trusted-card-body{
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #6B6C70;

}
.partner-text{
font-size: 64px;
font-weight: 600;
line-height: 78.02px;
letter-spacing: -0.03em;
text-align: center;
color: #FFF;
padding: 0 5rem;
}

.partner-counts{
    margin-top: 150px;
    padding: 0 10rem;
    text-align: left;
    .col-4{
        padding: 0 5rem;
    }
}

.partner-counts-header{
font-size: 40px;
font-weight: 600;
line-height: 48.76px;
letter-spacing: -0.03em;
text-align: center;
color: #FFF;
text-align: left;
}

.partner-counts-body{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.footer-section{
        height: 1px;
        width: 100%;
        margin: 200px auto 20px auto;
        background: linear-gradient(90deg, #C038ED 0%, #32A7EB 100%);
}

.footer-logo{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    img{
        margin-right: 8px;
    }
}
.feature-moto{
font-size: 40px;
font-weight: 600;
line-height: 48.76px;
letter-spacing: -0.03em;
text-align: left;
color: #FFF;
}
.policy-text{
font-size: 16px;
font-weight: 600;
line-height: 19.5px;
letter-spacing: -0.03em;
text-align: left;
color: #FFF;
}
.policy-text a{
    text-decoration: none !important;
    color: #FFF;
}

.footer {
    hr {
        border-bottom: 1px solid #7b7b7b !important;
    }
}

.card-zindex {
    position: relative;
    z-index: 1000 !important;
    height: auto !important;
}

.mobile-footer-section{
    div{
    img{
        display: none;
    }
}
}
.mobile-footer-section-img{
    margin-left: 4px;
}
.concern-date {
    /* margin-top: 2rem; */
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #FFF;
    bottom: 24px;
}
@media screen and (max-width: 768px) {
    .sub-card{
    border-bottom: 1px solid #282D41;
    border-radius: 0px;
}
.sub-card-header{
    font-size: 32px;
    line-height: 48.02px;
}
    .sub-card-footer{
    max-width: 100%;
    }

    .landscape-text{
    margin-top: 16px;
    }

    .concerns-heading{
    font-size: 32px;
    line-height: 48.02px;
}

    .margin-top-50{
    margin-top: 16px;
    }

    .trusted-section{
    padding: 0rem;
    height: unset;
    }

    .overflow-scroll{
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

    .trusted-text{
    font-size: 32px;
    line-height: 48.02px;
    }

    .trusted-card{
    width: unset;
    }

    .partner-text{
    font-size: 32px;
    line-height: 48.02px;
}

    .partner-counts{
    padding: 0 2rem !important;
    margin-top: 32px;
    }
    .mobile-partner-counts{
        padding: 0 !important;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #282D41;
    }

    .partner-counts-header{
    text-align: center;
    }

    .partner-counts-body{
    text-align: center;
    }

    .footer-section{
    margin-top: 32px;
    }

.full-width-scroll{
    width: calc(100vw - 1.5rem) !important;
    overflow-x: scroll;
}
.full-width-scroll-section{
    width: calc(4 * 80vw);
    display: flex;
}

    .mobile-footer-section{
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #2B303B;
    justify-content: space-between;
}
.mobile-footer-section-tag{
    text-decoration: none !important;
    color: #FFF;
}
.mobile-footer-section-img{
    display: block !important;
    margin-left: 4px;
}
}