.apidoc{
.doc-header-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 34.13px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
}

.doc-nav-block {
    background: #16181D !important;
    border: 2px solid #2B303B !important;
    min-height: 200px !important;
    border-radius: 8px !important;

    ul {
        background: transparent !important;
    }
}

.doc-json-block {
    background: #16181D !important;
    border: 2px solid #2B303B !important;
    min-height: 200px !important;
    max-height: 500px !important;
    overflow-y: scroll !important;
    border-radius: 8px !important;

    ul {
        background: transparent !important;
    }
}

.doc-curl-block {
    background: #16181D !important;
    border: 2px solid #2B303B !important;
    min-height: 200px !important;
    border-radius: 8px !important;
}

.curl-content {
    font-size: 12px !important;
    background: #000 !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
}

.note-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: #666666;
}

.doc-nav-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
    padding: 0.5rem;
}

.doc-nav-active {
    background: #DFDFDF;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #16181D;
    padding: 0.5rem;
}

.test-form-section {
    .form-control {
        color: #000 !important;
    }
    
    label {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 14.63px !important;
        letter-spacing: 0.7228261232376099px !important;
        color: #C1C1C1 !important;
    }

    .form-check-input:checked {
        background-color: #00A656 !important;
        border-color: #00A656 !important;
    }
}

.text-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
}

.text-content {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: -0.03em;
    color: #DFDFDF;
    word-wrap: break-word;
}

.api-row>* {
    width: auto !important;
    align-items: center !important;
}

.api-row {
    .generate-btn {
        background: #1B1D25;
        border-radius: 8px;
        color: #DFDFDF;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        letter-spacing: -0.03em;
        border: 0;
    }
    
    .generate-btn:hover {
        background: #DFDFDF;
        border-radius: 8px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        letter-spacing: -0.03em;
        border: 0;
    }
}

.hint-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: #666666;
}

.curl {
    color: #50fa7b !important;
}

.curl-header {
    color: #d63384 !important;
}

.curl-text {
    color: #f1fa8c !important;
}

.wid-20 {
width: 20% !important;
}

.wid-40 {
    width: 40% !important;
}
}