.leaked-repos-block {
    background: #16181D;
    border: 2px solid #2B303B;
    border-radius: 8px;
    min-height: calc(100vh - 225px);
    max-height: calc(100vh - 225px);

    .header-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 21.94px;
        letter-spacing: -0.03em;
        color: #DFDFDF;
    }

    .search-field{
        background: #131313 !important;
        border: 1px solid #2B303B !important;
        border-radius: 8px !important;
        color: #fff !important;
    }

    .search-field::placeholder{
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 14.63px !important;
        letter-spacing: -0.03em !important;
        color: #979797 !important;
    }
    .search-field:focus{
        background: #333;
        color: #fff;
        border: none;
        outline: none;
    }

    .repos-block {
        min-height: calc(100vh - 375px);
        max-height: calc(100vh - 375px);
        overflow-y: scroll;
    }

    .repos-row {
       display: flex;
       padding: 0.75rem 0;
       border-bottom: 0.5px solid #2E323E;
       justify-content: space-between;

       .repo-text {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.5px;
            letter-spacing: -0.03em;
            color: #DFDFDF;
       }

       .repo-text-badge {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.07px;
        letter-spacing: -0.03em;
        color: #16181D;
        padding: 0.25rem 0.75rem;
        border-radius: 4px;
        justify-content: center;
       }
    }

    .repos-row-highlight {
        display: flex;
        padding: 0.75rem 0;
        border-bottom: 0.5px solid #2E323E;
        justify-content: space-between;

        background: #000;
        border-radius: 8px;
        align-items: center;

        .repo-text {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.5px;
            letter-spacing: -0.03em;
            color: #FFFFFF;
       }

       .repo-text-badge {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.07px;
        letter-spacing: -0.03em;
        color: #16181D;
        padding: 0.25rem 0.75rem;
        border-radius: 4px;
        justify-content: center;
       }
    }

    .green {
        background: #6CFF83;
    }

    .purple {
        background: #B68BFF;
    }

    .orange {
        background: #FBB767;
    }

    .yellow {
        background: #F8FB67;
    }
    
}