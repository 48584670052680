.pre-login-screen {
    background: black;
    width: 100vw;
    height: 100vh;
}

.pre-login-screen-header {
    position: relative;
    height: 100px;
    z-index: 100;
}

.pre-login-content-screen {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -100px;

    input {
        height: 48px !important;
    }
}

.pre-login-content-block {
    position: relative;
    background: #1C1C1C;
    width: 30%;
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
    .pre-login-content-block {
    width: 100%;
    }
}

.create-btn {
    background-color: #FFFFFF;
    border: none;
    color: #000000;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.03em;
}

.header-btn {
    background-color: #FFFFFF;
    border: none;
    color: #000000;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.03em;
}

.cursor-pointer {
    cursor: pointer;
}

.account-link {
    font-size: 16px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.signin-header {
    font-size: 28px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
    text-align: center;
}
.signin-body{
    font-size: 20px;
    font-weight: 600;
    line-height: 31.01px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
    text-align: center;
}

.form-control {
    border: none;
    color: #FFFFFF !important;
}

.form-control-lg {
    font-size: 1rem !important;
    color: #FFFFFF;
}
.pre-login-content-screen {
input {
    background: #333333 !important;
}

  
::-ms-input-placeholder {
    color: #E6E6E6 !important;
  }

  ::-webkit-input-placeholder {
    color: #E6E6E6 !important;
  }

  ::-moz-placeholder {
    color: #E6E6E6 !important;
  }

  ::placeholder {
    color: #E6E6E6 !important;
}
}
.error-msg {
    color: red;
    font-size: 14px !important;
}