.payment-form{
    .domains-block {
    height: auto !important;
    overflow-y: scroll;
}
.font-32{
    font-size: 32px;

}
.font-28{
    font-size: 28px;
    
}
.btn {
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #16181D !important;
    border: 1px solid #FFFFFF !important;
    padding: 0.5rem 2rem !important;
}
.add-domain-btn {
    background: transparent !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #E6E6E6 !important;
        border: 1px solid #2B303B !important;
        padding: 0.5rem 1rem !important;
}

.add-on {
    .btn {
        background: transparent !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #E6E6E6 !important;
        border: 1px solid #2B303B !important;
        padding: 0.5rem 1rem !important;
    }
}
.mobile{
    color: #000 !important;
}
.danger-font{
    color: #FF0000;
}
.font-white{
    color: #FFFFFF;
}
.model-body{
    height: 300px !important;
    position: relative;
}
.center-align{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
}


.domains-section {
    width: 50% !important;
}

.plans-modal {
    .domains-section {
        width: 100% !important;
    }
}