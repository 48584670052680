.pre-login-content-screen {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -100px;

    input {
        height: 48px !important;
    }
}