.modal-content {
    background: #1c1c1c !important;
    color: #FFFFFF !important;
}

.font-12 {
    font-size: 12px;;
}

.sub-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: -0.03em;
    color: #515560;
}

.domains-modal {
    .btn-close {
        display: none;
    }

    .btn {
        color: #FFFFFF !important;
        background: #000 !important;
        border: none !important;
    }

    .modal-content {
        top: 15vh !important;
        border: 2px solid #2B303B !important;
    }

    .modal-header {
        display: block !important;
        border-bottom: 1px solid #2B303B !important;
    }

    .domains-card {
        background: #1C1C1C;
        padding: 0.5rem !important;
    }

    .domains-block {
        height: 300px !important;
        overflow-y: scroll !important;
    }
}

.continue-btn {
    .btn {
        background: #FFFFFF !important;
        color: #000 !important;
        border-radius: 8px !important;
        padding: 0.5rem 1rem !important;
    }
}