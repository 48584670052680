.payment{
.select-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
    letter-spacing: -0.03em;
    color: #E6E6E6;
}

.payment-method-text {
    font-size: 16px;
    font-weight: 900;
    line-height: 18.75px;
    color: #FFFFFF;
    /* border-bottom: 1px solid #FFFFFF; */
    border-bottom: 1px solid #A8368B;
    width: fit-content;
}

.payment-card {
    background: #FFFFFF;
    padding: 1.5rem;
    border-radius: 8px;
}

.payment-cost-card {
    background: #FFFFFF;
    padding: 1.5rem;
    height: max-content;
    border-radius: 8px;
}

.payment-field{
    background: #FFFFFF !important;
    border-bottom: 1px solid #848484 !important;
    border: none;
    border-radius: 0;
    color: #000 !important;
}
.payment-field::placeholder{
    color: #999999 !important;
}
.payment-card {
    .payment-field:focus{
        background-color: #FFFFFF;
        color: #000;
        border: none;
        outline: none;
    }

    .form-control {
        border: none;
        color: #000 !important;
    }
}
.info-text{
    color: #848484;
    font-size: 1.5rem;
    text-align: center;
}
.payment-card {
    .form-control-sm {
        width: 75px;
    }
}

.form-select-lg {
    border-radius: 4px !important;
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
}

.company-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #333333
}

.payment-option {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    color: #333333;
}

.payment-cost {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    color: #333333;
}

.payment-details {
    border-bottom: 1px solid #666666;
}

.grand-total {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #333333;
}

.total-cost {
    font-size: 25px;
    font-weight: 900;
    line-height: 29.3px;
    color: #333333;
}

label {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #666666
}

.pay-btn {
    background: #3A1AEC !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    border: 1px solid #3A1AEC !important;
    padding: 1rem 2rem !important;
}

.payment-card {
    .btn {
        background: #3A1AEC !important;
        border-radius: 4px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
        color: #FFFFFF !important;
        border: 1px solid #3A1AEC !important;
        padding: 1rem 2rem !important;
    }
}

.ssl-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #333333;
}

.payment-card {
    .form-check-input[type=checkbox] {
        border-radius: 0.25em;
        background-color: #FFFFFF !important;
    }

    .form-check-input {
        --bs-form-check-bg: #16181d !important;
        flex-shrink: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.25em;
        vertical-align: top;
        appearance: none;
        background-color: var(--bs-form-check-bg);
        background-image: var(--bs-form-check-bg-image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: var(--bs-border-width) solid var(--bs-border-color);
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

.save-card-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #333333;
}
}