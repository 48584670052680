.page-link{
    color: #FFF !important;
    background-color: #000 !important;
}
.active>.page-link, .page-link.active {
    background: #FFFFFF !important;
    border: 1px solid #FFFFFF !important;
    color: #000 !important;
}

.leaked {
    .pagination {
        --bs-pagination-padding-y: 0.180rem !important;
    }

    .page-link {
        font-size: 14px !important;
    }
}
