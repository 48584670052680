.search-field{
    background: #131313 !important;
    border: 1px solid #2B303B !important;
    border-radius: 8px !important;
    color: #fff !important;
}

.search-field::placeholder{
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.63px !important;
    letter-spacing: -0.03em !important;
    color: #979797 !important;
}
.search-field:focus{
    background: #333;
    color: #fff;
    border: none;
    outline: none;
}

.repos-row-mobile  {
    display: flex !important;
    align-items: center !important;

    .repo-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: -0.03em;
        color: #DFDFDF;
   }

   .repo-text-badge {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.03em;
    color: #16181D;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
   }
}

/* .repos-row-mobile>* {
    width: auto !important;
    align-items: center !important;
} */

.green {
    background: #6CFF83;
}

.purple {
    background: #B68BFF;
}

.orange {
    background: #FBB767;
}

.yellow {
    background: #F8FB67;
}

.w-5 {
    width: 5%;
    max-width: 5vw !important;
}

.w-20 {
    width: 20%;
    max-width: 20vw !important;
}

.w-25 {
    width: 25%;
    max-width: 25vw !important;
}

.w-30 {
    width: 30%;
    max-width: 30vw !important;
}

.w-40 {
    width: 40%;
    max-width: 40vw !important;
}

.w-50 {
    width: 50%;
    max-width: 50vw !important;
}

.w-90 {
    width: 90%;
    max-width: 90vw !important;
}

.repo-files-section {
    display: contents;

    .table {
        --bs-table-color-type: initial;
        --bs-table-bg-type: initial;
        --bs-table-color-state: initial;
        --bs-table-bg-state: initial;
        --bs-table-color: #FFFFFF;
        --bs-table-bg: transparent;
        --bs-table-border-color: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: var(--bs-emphasis-color);
        --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
        --bs-table-active-color: var(--bs-emphasis-color);
        --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
        --bs-table-hover-color: var(--bs-emphasis-color);
        --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
        width: 100%;
        margin-bottom: 1rem;
        vertical-align: top;
        border-color: var(--bs-table-border-color);
    }

    th {
        color: #A9A9A9 !important;
        font-size: 13px;
        font-weight: 500;
        line-height: 15.85px;
        letter-spacing: 0.5px;
        padding: 10px 0;
    }

    td {
        color: #D6D6D6 !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.31px;
        letter-spacing: 0.5px;
        padding: 4px 0;
    }

    td{
        overflow: hidden;
        word-wrap: break-word !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }

    .files-table {
        min-height: calc(100vh - 450px);
        max-height: calc(100vh - 450px);
        overflow-y: scroll !important;

        background-color: #000 !important;

        .tr-highlight {
            background: #1B1D25 !important;
        }
    }

    table {
        caption-side: bottom !important;
        border-collapse: collapse !important;
    }

    @media screen and (max-width: 768px){
        .hide-in-mobile{
            display: none !important;
        } 
    }

    @media screen and (min-width: 769px){
        .hide-in-desktop{
            display: none !important;
        } 
    }
    
    .file-name {
        color: #0d6efd !important;
        text-decoration: none;
    }

    .file-row-snippet {
        /* width: 85vw !important; */
        background-color: #000 !important;
        overflow-x: scroll;

        td {
            border: none !important;
            max-width: 70vw !important;
        }

        pre {
            white-space: pre-wrap !important;
            display: contents !important;
        }
    }

}

.repos-section {
    min-height: 550px;
    max-height: 550px;
    overflow-y: scroll;
}

.no-repos-found {
    min-height: 75vh;
    max-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}