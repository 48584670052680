.min-height-full{
    min-height: calc(100vh - 80px);
    padding: 1.5rem;
}
.pre-subscription {
    .form-control {
        border: none;
        color: #000 !important;
    }

    .btn {
        /* background-color: #3A1AEC !important; */
        color: #E6E6E6 !important;
        padding: 0.5em 1em !important;
        border: none !important;
        border-radius: 4px !important;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%) !important;

    }
}
td{
    overflow: hidden;
    word-wrap: break-word !important;
}

.search-field::placeholder{
    color: #909090 !important;
}

.search-btn-disable { 
    .btn {
        height: 64px !important;
        width: 120px !important;
        max-width: 120px !important;
        font-size: 24px !important;
        padding: 0.5em 1em !important;
        border: 1px solid #2B303B !important;
        border-radius: 4px !important;
        background: #FFFFFF !important;
        color: #000 !important;
        opacity: 30% !important;
    }
}

.search-btn-enable { 
    .btn {
        height: 64px !important;
        width: 120px !important;
        max-width: 120px !important;
        font-size: 24px !important;
        color: #E6E6E6 !important;
        padding: 0.5em 1em !important;
        border: none !important;
        border-radius: 4px !important;
        background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
    }
}
.margin-right-n1{
    margin-right: -1px !important;
}


::-webkit-scrollbar {
    display: none !important;
  }

  .search-container {
    padding: 1.5rem 103px !important;
}

.w-156 {
    width: 156px !important;
    max-width: 156px !important;
}

.search-container {
    .form-select {
        background-size: 24px 16px !important;
        font-size: 24px !important;
        height: 64px !important;
        width: 100% !important;
       }

    .form-control-lg {
        height: 64px !important;
        border-radius: 4px !important;
        font-size: 24px !important;
        background: #FFF !important;
    }
    .form-control-lg:focus{
        box-shadow: none !important;
        border: 0;
      }
}

.gradient-text {
    background: linear-gradient(90deg, #33A7EA 0%, #B442ED 100%);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
max-width: fit-content;
}

.wid-15 {
    width: calc(100vw - 85vw) !important;
    max-width: calc(100vw - 85vw) !important;
}
.wid-35{
    width: calc(100vw - 65vw) !important;
    max-width: calc(100vw - 65vw) !important;
}

.wid-20 {
    width: calc(100vw - 80vw) !important;
    max-width: calc(100vw - 80vw) !important;
}
.wid-10 {
    width: calc(100vw - 90vw) !important;
    max-width: calc(100vw - 90vw) !important;
}

table {
    border-collapse: unset !important;
}

thead {
    th {
        padding: 1rem 0.5rem !important;
    }
}

    tbody {
        td {
            /* border-color: unset;
            border-style: dashed; */
            border-bottom: 1px dashed #2B303B;
            /* border-width: 0; */
            padding: 1rem 0.5rem !important;
        }
    } 
.search-section {
    display: flex;
}
.mobile-flex{
    display: block;
}
@media screen and (max-width: 768px) {
    .margin-right-n1{
        margin-right: 0px !important;
    }
    .min-height-full{
    padding: 0em;
    }
    .search-btn-disable { 
        .btn {
    width: 100% !important;
    max-width: 100% !important;
        }
    }
    .search-btn-enable { 
        .btn {
    width: 100% !important;
    max-width: 100% !important;
}
    }
    .search-container {
    padding:0 !important;
    }
    .search-section {
    display: block;
    }
.full-width-mobile{
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        padding: 0.5rem !important;
    }
.disp-none-mobile{
        display: none !important;
    }
    .mobile-flex{
    display: flex !important;
    margin-bottom: 8px !important;
    p{
    padding-right: 8px;
    }
}
}
.curser-pointer{
    cursor: pointer;
}

.modal-content {
    background: #1c1c1c !important;
    color: #FFFFFF !important;
}

.alert-modal {
    .btn-close {
        display: none;
    }

    .btn {
        color: #FFFFFF !important;
        background: #000 !important;
        border: none !important;
    }

    .modal-content {
        top: 4vh !important;
        border: 2px solid #2B303B !important;
        max-height: 85vh !important;
        overflow-y: scroll !important;
    }

    .modal-header {
        display: block !important;
        border-bottom: 1px solid #2B303B !important;
    }

    .domains-card {
        background: #1C1C1C;
        padding: 0.5rem !important;
    }

    .domains-block {
        height: 300px !important;
        overflow-y: scroll !important;
    }
}