.full-width{
    width: 100vw;
    height: 100vh;
    position: relative;
    color: #FFF;
    background: #000;
}
.center-alligned{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.home-button{
    background: #FFFFFF;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 29.26px;
    letter-spacing: -0.03em;
    color: #131313;
    cursor: pointer;
}